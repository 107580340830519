#CarouselContainer{
	margin-bottom: 10px;
	padding: 30px 50px;
	height: 59%;
	animation: myAnim 1.3s ease 0s 1 normal forwards;
}
.CarouselTitre{
	width:50%;
	position: absolute;	
	z-index:3;
	transform: rotate(0.5turn);
	height:600px;
}

.CarouselImg{
	position: relative;
	height: 50%;
	width:100%;
}
.Carousel div{
	position: relative;
	width:100%;
	height: 100%;
}


.Carousel div h3 {
	display: inline-block;
	font-size:4rem;
	padding:5px 100px ;
	transform: rotate(0.5turn);
	color:white;
	background: rgb(255,255,255);
	background: linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(117,117,117,0.5382353625043768) 45%, rgba(2,4,4,0.7539216370141807) 100%);
}

@media only screen  and (max-width:1800px)
{
	.CarouselTitre{
		height:450px
	}
	.Carousel div h3 {
		font-size:3rem;
	}
}

@media only screen  and (max-width:1300px) {
	.CarouselTitre{
		height:400px
	}
	.Carousel div h3 {
		font-size:2rem;
	}

}

@media only screen  and (max-width:1000px) {
	.CarouselTitre{
		height:200px
	}

	.Carousel div h3 {
		font-size:1.5rem;
	}

}

