#sectionContact{
	background-color: white;
	width:100%;
}
#ContactContainer{
	border: 1px solid #4d1f82;
	max-width: 1900px;
	padding:50px;
}
#ContactContainer h1{
	text-align: center;
	padding: 5px;
	margin: auto;
	width: 50%;
	font-size: 3rem;
}
#contact{
	display:flex;
	height: 100%;
	padding-top: 40px;
	justify-content: space-between;
}
#formulaire{
	display: flex;
	justify-content: center;
	align-items: center;
	width:50%;
}

#contact form{
	height: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width:100%;
}
#contact form div{
	width:50%;
	height:100%;
	display: flex;
	flex-direction: column;

}
#infoform {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
#infoForm input{
	height: 20%;
	width:100%;
	margin-bottom: 54px;
}

#contact form input{
    font-size: 1.5rem;
	padding-left: 5px;

}

#textSubmit{
	height:100%;	
	width:80%;
	margin: 70px;
	justify-content: space-between;
}
#textSubmit textarea{
	height:60%;
	padding: 5px;
}
#contact form input::placeholder {
    font-size: 1.5rem;
}

#contact form textarea{
	padding: 5px;
    font-size: 1.5rem;
	font-family: sans-serif;
}

#contact form textarea::placeholder {
    font-size: 1.5rem;
	font-family: sans-serif;
}

#info{
	width:50%;
	display:flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.5rem;
	padding-top: 50px;
}

#info a{
	color: black;
	text-decoration: none;
}
#info h3 {
	margin: 0;
}
#info p{
	margin: 5px;
}
#contactLogo{
	margin-top: 20px;
	text-align: center;
}
#contactLogo img{
	width:100%;	
}
#submit {
	padding: 0.8rem 0;
	background-color: #4d1f82;
	color: white;
	text-align: center;
	font-family: metropolis;
	font-size: 2rem;
	width: 50%;
	margin: auto;
}
