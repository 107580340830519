footer{
	background-color: white;
	display:flex;
	justify-content: space-between;
	padding:20px 90px;
	align-items:center;
	max-width: 1920px;
	margin: auto;
	opacity: 0;
	animation: myAnim 3s ease-in-out  0s normal forwards;
}

#logoOpa img{
	height: 100px;
}
#resaux{
	display: flex;
	align-items: center;
	justify-content:space-between;
	width:20%;
}
#resaux i{
	font-size:3rem;
	color:#472a9c;
}

 @keyframes myAnim {
  0% {
    opacity: 0;
  }
  40% {

    opacity: 0;

  }

  100% {
    opacity: 1;
  }
}

