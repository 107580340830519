.App{
	max-width: 1920px;
	margin: auto;
	animation: myAnim 2s ease 0s 1 normal forwards;
}

 @keyframes myAnim {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}
