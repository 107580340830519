nav { height:10%;
	border:1px solid black;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding-inline:80px;
	padding-bottom: 20px;
}

nav ul {
	display:flex;
	justify-content: space-between;
	width:40%;
}
nav ul li{
	list-style: none;
	font-size:1.5rem;
	color: white;
}
#logo_nav{
	width:250px;
}
#logo50_nav{
	width:100%;
}
.prestations{
	width:1466px;
	margin:50px auto;
	position: relative;
	color: white;
}

.prestationsTitre h2{
	margin: 0;
	background-image: url('../img/BANDE-TITRE.jpg');
	width: 100%;
	text-align: center;
	height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1920px;
}

.prestationsTitre{
	height: 30%;
	display: flex;
	align-items:center;
	justify-content: center;
	font-size: 3rem;
	color:white;
	text-decoration: underline;
}

.gridPrestation{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px; 
	grid-auto-rows: minmax(100px, auto);
	justify-content:center;
	background-color: #232322;
	padding-inline:50px ;
	animation: myAnim 3s ease-in-out  1 normal forwards;
}
.PrestationItem{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height:400px;
	width:570px;
	transition:2s;
	margin:10% auto;
	text-align:center;
	position:relative;
	overflow: hidden;
}
.PrestationItem img{
	width:100%;
	height:100%;
	object-fit: cover;
	
}
.infoItem{
	position: absolute;
	height: 100%;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	opacity: 0;
	transition: 1.5s;
	padding: 5%;
}
.infoItem:hover{
	opacity: 1;
	background-color: rgba(35,35,34,0.9);
}

.infoItemp{
	height: 60%;
}

.description_recette{
	overflow-y:auto;
	height: 50%;
}

.lienPrestations{
	background-color:black;
	color:white;
	font-weight:bold;
	font-size: 1.5rem;
	text-decoration: none;
	margin-left:70%;
}

.prestationInfo{
	border-top:1px solid white;
	text-align: left;
	padding-left:10%;
	width:70%;
	margin:0% auto;
}

.prestationInfo h3{
	font-size:1.4rem;
}

.prestationInfo span{
	padding-inline: 10px;
}
.prestationInfo p{
	margin: 0;
}
.duree {
	border-right: 1px solid white;
}

#lienDemande{
	border:1px solid white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 80px;
	width: 1000px;
	margin: auto;
}

#lienDemande p {

	font-size: 2rem;
}

#lienDemande span {
	font-size: 3rem;
}

#lienDemande button{
	background-color:#4B1972;
	border: none;
	color: white;
	font-size: 1.5rem;
	width: 200px;
	font-family: metropolis;
	padding: 15px;
	cursor: pointer;
	border: solid 1px white;
}

@media only screen and (max-width: 1340px){

	.gridPrestation {
		grid-template-columns: repeat(1, 1fr);
	}

}
 @keyframes myAnim {
  0% {
    opacity: 0;
  }
  40% {

    opacity: 0;

  }

  100% {
    opacity: 1;
  }
}

