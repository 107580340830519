#formulaireDemande{
	border:1px solid white;
	padding: 40px;
	margin-bottom: 40px;
}
#sectionDemande{
	width:60%;
	margin: auto;
}

#sectionDemande h1{
	color:white ;
	text-align: center;
	text-decoration: underline;
	font-size: 2.5rem;
}

#sectionDemande h4{
	color:white ;
	text-align: center;
	font-size: 1.5rem;
}

#sectionDemande form{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

#sectionDemande .inputBlock{
	width:48%;

	height: 100px;
	display: flex;
	flex-direction: column;
}
#sectionDemande .inputBlock input{
	height: 40px;
	background-color: #232322;
	color: #ffff;
}

#sectionDemande .inputBlock select{
	height: 40px;
	background-color: #232322;
	color: white;
	font-size: 1.5rem;
}

#sectionDemande .inputBlock label{
	color:white;
	font-size: 1.5em;
	margin-bottom: 2px;
}
#message{
	width: 100%;
	height: 400px;
	display: flex;
	flex-direction: column;
	color: white;
}
#message textarea{
	background-color: #232322;
	color: white;
	font-size: 1.3rem;
	height: 400px;

}
#sectionDemande #message label{
	color:white;
	font-size: 1.5em;
}

.sendBlock input{
	margin-top: 20px;
	background-color: white;
	color:#232322;

	font-weight: bold;
	font-size: 2rem;
}
.sendBlock #submit {
	padding: 10px 15px;
}