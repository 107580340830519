.titre{
	padding-top:10px;
	width: 100%;
	text-align: center;
	display :flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	color:white;
}

.titre img{
	width:20%;
}

.titre h2{
	animation: myAnim 1.3s ease 0s 1 normal forwards;
	font-size: 1.5rem;
	margin-bottom: 10px;
	font-weight: 400;
}
.titre h3{
	animation: myAnim 1.6s ease 0s 1 normal forwards;
	font-size: 2rem;
	font-weight: 600;
}

 @keyframes myAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes title{
	0%{
		height:300px;
	}
	50%{
		height:500px;
	}
	100%{
		height:300px;
	}
}

