#equipeComponent{
	border: 1px solid white;
	width:80%;
	margin: 30px auto;
}
#equipeComponent h2{
	color: white;
	text-align: center;
	font-size: 3rem;
}
.EquipeBlock h3 {
	font-size: 1.5rem;
}
.EquipeBlock p {
	font-size: 1.3rem;
	margin: 0 17px 20px;
}
.Equipe{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
	margin-top:50px;
	color: white;
}

.Equipe div {
	text-align:center;
	display:flex;
	flex-direction:column;
	margin:10px auto;
}

.equipeImg {
	width:350px;
	height:450px;
	border: 1px solid black;
	overflow: hidden;
}

.equipeImg img{
	object-fit: cover;
	height: 100%;
	width:100%;
}
@media only screen and (max-width: 1340px){

	.Equipe {
		grid-template-columns: repeat(2, 1fr);
	}

}

@media only screen and (max-width: 800px){

	.Equipe{
		grid-template-columns: repeat(1, 1fr);
	}

}

