a{
	text-decoration: none;
}
.Cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px; 
	grid-auto-rows: minmax(100px, auto);
	justify-content:center;
	background-color: #232322;
	padding-top:40px;
	padding-inline:50px ;
}

.card{
	display: flex;
	justify-content: center;
	align-items: center;
	height:400px;
	width:500px;
	transition:2s;
	margin:auto;
	text-align:center;
	position:relative;
	overflow: hidden;
}
.card:hover{
	height: 415px;
}

.Cards div h3{
	transition:2s;
	position: relative;
	width: 100%;
	font-size:2.2rem;
	color:white;
	margin:auto;
	z-index: 2;
	background-color: rgba(35,35,34,0.6);
	padding-top: 30%;
	height: 100%;
	padding: 30% 10px;
}

.Cards div h3:hover{
	transition: 0.5s;
	background-color: rgba(35,35,34,0);
	color:#232322;
	text-decoration: underline;
}


.imgCards{
	position: absolute;
	width:100%;
	height: 110%;
	top:0;
	left:0;
	object-fit:cover;
}


@media only screen and (max-width: 1340px){

	.Cards {
		grid-template-columns: repeat(2, 1fr);
	}

}

@media only screen and (max-width: 800px){

	.Cards {
		grid-template-columns: repeat(1, 1fr);
	}

}

