.Partenaire{
	margin-top: 40px;
	margin-bottom: 40px;
	background-color: white;
	padding: 20px;
}
.Partenaire h2{
	
	
	color: black;
	margin:20px auto;
	text-align: center;
	width:50%;
	font-size: 3rem;
}
#partenaire {
	display:flex;
	justify-content: space-between;
	padding: 5vh 2vw;
}

#partenaire div{
	text-align:center;
	height: 170px;
	width:170px;
	/* background-color: #988A8A;
 */}

#partenaire div:hover{
	cursor:pointer;
}

#partenaire img{
	width:100%;
	height: 100%;
	object-fit: contain;
}
